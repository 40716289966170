import React, { ComponentProps, PropsWithChildren } from 'react'
import { cn } from '@sq/components'
import { LoaderCircleIcon } from 'lucide-react'

type Props = ComponentProps<'svg'> & {
  isVisible: boolean
}

export default function Spinner({ isVisible, className, ...props }: PropsWithChildren<Props>) {
  if (!isVisible) return null

  return (
    <div className="animate-in fade-in-10 duration-300">
      <LoaderCircleIcon
        {...props}
        className={cn('text-white-200 status size-6 animate-spin dark:text-gray-600', className)}
      />
    </div>
  )
}
