import React from 'react'
import { cn, Button } from '@sq/components'
import { XMarkIcon } from '@heroicons/react/24/outline'

type Props = {
  options: ReadonlyArray<string>
  selectedOptionIndex?: number
  onToggle: (index: number) => void
  className?: string
}

export default function RadioButtonGroup({
  options,
  selectedOptionIndex,
  className,
  onToggle,
}: Props) {
  return (
    <div className={cn('flex flex-row flex-wrap items-start gap-2', className)}>
      {options.map((option, index) => (
        <Button
          key={option}
          variant="gray"
          type="button"
          onClick={() => onToggle(index)}
          className={cn(
            'w-auto text-base font-normal',
            index === selectedOptionIndex && 'bg-sq-gray-300'
          )}
        >
          {option}
          {index === selectedOptionIndex && <XMarkIcon className="size-4" />}
        </Button>
      ))}
    </div>
  )
}
